import React from "react"

import Logo from "./Logo"
import Nav from "./Nav"
import FadeInLink from "./transitions/FadeInLink"

const Header = () => {
  return (
    <header id="Header" className="Header">
      <div className="Header__inner outer-padding-x">
        <Logo />
        <nav className="Header__nav">
          <ul className="list-unstyled">
            <li>
              <FadeInLink to="/projects" className="Link--nav" type="default"><span>01.</span> Projects</FadeInLink>
            </li>
            <li>
              <FadeInLink to="/services" className="Link--nav" type="default"><span>02.</span> Services</FadeInLink>
            </li>
            <li>
              <FadeInLink to="/about" className="Link--nav" type="default"><span>03.</span> About</FadeInLink>
            </li>
            <li>
              <FadeInLink to="/contact" className="Link--nav" type="default"><span>04.</span> Contact</FadeInLink>
            </li>
          </ul>
        </nav>
        <Nav />
      </div>
    </header>
  )
}

export default Header
