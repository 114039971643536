
import React, { Component } from 'react';
import TransitionLink from "gatsby-plugin-transition-link"

const fade = ({ node, direction, type }) => {

  // const time = length;
  // node.style.transition = `opacity ${time}s ease ${time}s`;

  if(type === 'menu' && direction === 'out') {
    // Make sure to scroll to top
    if (typeof window !== 'undefined') {
      setTimeout(() =>{ document.getElementById('Nav__checkbox').checked = false; }, 10);
    }
    // setTimeout(() =>{ window.scrollTo(0, 0) }, 501);
  }

  if(direction === 'in') {
    node.querySelector('.main-wrapper').style.opacity = 0;
    setTimeout(() =>{ node.firstChild.classList.add('loading-overlay--show'); }, 500);
    setTimeout(() =>{ node.querySelector('.main-wrapper').style.opacity = 1; }, 501);
    setTimeout(() =>{ node.firstChild.classList.add('loading-overlay--animateOut'); }, 750);
  } else {
    // remove old classes
    node.firstChild.classList.remove('loading-overlay--show');
    node.firstChild.classList.remove('loading-overlay--animateOut');
    // add new
    setTimeout(() =>{ node.firstChild.classList.add('loading-overlay--animateIn'); }, 10);
  }

}

class FadeInLink extends Component {

  constructor(props) {
		super(props)
  }
  
  render() {

    const {
      duration,
      type,
			...props
    } = this.props
    
    const lengthOut = 0.5;
    const lengthIn = 1;

    return (
      <TransitionLink
        {...props}
        exit={{
          length: lengthOut,
          trigger: ({ node }) =>
            fade({ node, direction: 'out', type }),
        }}
        entry={{
          length: lengthIn,
          appearAfter: 0.3,
          trigger: ({ node }) =>
          fade({ node, direction: 'in', type }),
        }}
        {...props}>
        {props.children}
      </TransitionLink>
    )
  }

}

export default FadeInLink