import React, { Component } from 'react';
import FadeInLink from "./transitions/FadeInLink"
import noScroll from 'no-scroll';

class Logo extends Component {

  constructor(props) {
    super(props);
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  handleLinkClick(e) {
    if(this.props.type && this.props.type === 'nav') {
      // If clicking current page just hide menu again
      if(e.target.hasAttribute('aria-current')) {
        if (typeof window !== 'undefined') {
          document.getElementById('Nav__checkbox').checked = false;
        } 
      }
      noScroll.toggle();
    }
  }

  render() {
    const theType = this.props.type === 'nav' ? 'menu' : 'default';
    return (
      <FadeInLink to="/" className="Logo" type={theType} onClick={this.handleLinkClick}>
        <figure>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 208.9 226.5" xmlSpace="preserve">
            <title>hhb logo</title>
            <path d="M119.1,95.7l-67.2,0v109.9h11.4v-56.6h55.8c23,0,41.6,18.6,41.6,41.6v15.1H172V190v-41.1 C172,119.6,148.3,95.9,119.1,95.7z M107.6,162.2c-0.3-0.3-0.4-0.8-0.2-1.2c0.2-0.4,0.6-0.7,1-0.7h21.8V190v15.6h-11.4v-15.1 C118.8,179.5,114.5,169.6,107.6,162.2z M75.6,22.1c0-0.5-0.3-0.9-0.7-1c-0.4-0.2-0.9-0.1-1.2,0.2L54.4,40.6l21.2,21.2V22.1z M179.8,73.2L128,21.4c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.4c-12.3,13.1-12,33.8,0.8,46.6l51.8,51.8 c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.4C192.8,106.6,192.6,86,179.8,73.2z M64.8,85h17.5c0.5,0,0.9-0.3,1-0.7 c0.2-0.4,0.1-0.9-0.2-1.2L21.2,21.3c-0.3-0.3-0.8-0.4-1.2-0.2c-0.4,0.2-0.7,0.6-0.7,1l0,183.5h11.4V72.8h21.8 C59.3,72.8,64.8,78.3,64.8,85z"/>
          </svg>
        </figure>
      </FadeInLink>
    )
  }
}

export default Logo