import React from "react"

import FadeInLink from "./transitions/FadeInLink"
import Logo from "./Logo"
import MusicPlayer from './MusicPlayer'
import DarkMode from './DarkMode'
import Observer from './Observer'

import icons from '../images/icons.svg';
const ChevronUp = icons + '#chevron-up';

const Footer = () => (
  <>
    <footer className="Footer">
      <div className="outer-padding-x">
        <div className="Footer__inner border-top-medium-gray border-bottom-medium-gray">
          <div>
            <Logo />
            <div className="Footer__contact">
              <p>Got a project?</p>
              <FadeInLink to="/contact" className="Link--nav" type="default">Let's talk</FadeInLink>
              <a href="mailto:henrik@hhb.dk">henrik@hhb.dk</a>
              <address className="Footer__address">
                <a href="https://www.google.com/maps/place/Vestergade+18C,+1456+K%C3%B8benhavn/@55.677693,12.5671603,17z/" target="_blank" rel="noopener noreferrer">
                  Vestergade 18C<br></br>
                  Copenhagen
                </a>
              </address>
            </div>
          </div>
          <nav>
            <h4>Navigation</h4>
            <ul className="list-unstyled">
              <li>
                <FadeInLink to="/projects" className="Link--nav" type="default">Projects</FadeInLink>
              </li>
              <li>
                <FadeInLink to="/services" className="Link--nav" type="default">Services</FadeInLink>
              </li>
              <li>
                <FadeInLink to="/about" className="Link--nav" type="default">About</FadeInLink>
              </li>
              <li className="comingsoon">
                <a href="https://blog.hhb.dk/" className="Link--nav" target="_blank" rel="noopener noreferrer"><span>Blog</span><svg className="fill-black"><use xlinkHref={ChevronUp}></use></svg></a>
              </li>
              <li>
                <FadeInLink to="/lab" className="Link--nav" type="default">Lab</FadeInLink>
              </li>
              <li>
                <FadeInLink to="/contact" className="Link--nav" type="default">Contact</FadeInLink>
              </li>
            </ul>
          </nav>
          <div>
            <h4>Elsewhere</h4>
            <ul className="list-unstyled">
              <li>
                <a href="https://www.linkedin.com/in/henrik-herland-boelsmand-b35b1060/" className="Link--nav" target="_blank" rel="noopener noreferrer">LinkedIn</a>
              </li>
              <li>
                <a href="https://www.instagram.com/henrik_hhb/" className="Link--nav" target="_blank" rel="noopener noreferrer">Instagram</a>
              </li>
              <li>
                <a href="https://github.com/henrikhb" className="Link--nav" target="_blank" rel="noopener noreferrer">GitHub</a>
              </li>
              <li>
                <a href="http://codepen.io/henrikboelsmand/" className="Link--nav" target="_blank" rel="noopener noreferrer">Codepen</a>
              </li>
              <li>
                <FadeInLink to="/newsletter" className="Link--nav" type="default">Newsletter</FadeInLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="Footer__bottom outer-padding-x">
        <p>© {new Date().getFullYear()} | GMT+1: {new Date().toLocaleTimeString('da-DK').slice(0, -3) }</p>
        <FadeInLink to="/now" type="default">Now</FadeInLink>
        <FadeInLink to="/privacy" type="default">Privacy</FadeInLink>
        <MusicPlayer />
        <DarkMode />
      </div>
    </footer>
    <Observer/>
  </>
)

export default Footer