import React, { Component } from 'react';

import icons from '../images/icons.svg';
const SunIcon = icons + '#sun';
const MoonIcon = icons + '#moon';

class DarkMode extends Component {

  state = {
    darkMode: false,
  };

  wrapper = null;

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.wrapper = document.getElementsByClassName("wrapper")[0];
      const darkModeEnabled = JSON.parse(localStorage.getItem('darkMode'));
      // console.log('Darkmode local storage value on init: ', darkModeEnabled);
      if(darkModeEnabled) {
        this.wrapper.classList.add('darkmode');
        this.setState({ darkMode: true });
      } else {
        localStorage.setItem('darkMode', 'false');
      }
    }
  }

  toggleDarkmode() {
    this.setState(prevState => ({
      darkmode: !prevState.darkmode
    }));
    console.log('toggle darkmode');
    if(!this.state.darkmode) {
      this.wrapper.classList.add('darkmode');
      localStorage.setItem('darkMode', 'true');
    } else {
      this.wrapper.classList.remove('darkmode');
      localStorage.setItem('darkMode', 'false');
    }
  }

  render() {
    return (
      <button className="DarkMode" aria-label="Toggle darkmode" onClick={ () => this.toggleDarkmode() }>
        <div className="DarkMode__inner">
          <div className="DarkMode__sun">
            <svg className="fill-black">
              <use xlinkHref={SunIcon}></use>
            </svg>
          </div>
          <div className="DarkMode__moon">
            <svg className="fill-black">
              <use xlinkHref={MoonIcon}></use>
            </svg>
          </div>
        </div>
      </button>
    )
  }

}

export default DarkMode