/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onInitialClientRender = () => {
  const emojis = [
    ["💙"],
    ["🦊"],
  ];
  console.log('Made with ' + emojis[0] + emojis[0] + emojis[0] + ' by https://hhb.dk ' +  emojis[1]);
}