// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lab-js": () => import("./../../../src/pages/lab.js" /* webpackChunkName: "component---src-pages-lab-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-now-js": () => import("./../../../src/pages/now.js" /* webpackChunkName: "component---src-pages-now-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-project-bakoba-js": () => import("./../../../src/pages/project/bakoba.js" /* webpackChunkName: "component---src-pages-project-bakoba-js" */),
  "component---src-pages-project-bankbudgettet-js": () => import("./../../../src/pages/project/bankbudgettet.js" /* webpackChunkName: "component---src-pages-project-bankbudgettet-js" */),
  "component---src-pages-project-careermentor-js": () => import("./../../../src/pages/project/careermentor.js" /* webpackChunkName: "component---src-pages-project-careermentor-js" */),
  "component---src-pages-project-tada-js": () => import("./../../../src/pages/project/tada.js" /* webpackChunkName: "component---src-pages-project-tada-js" */),
  "component---src-pages-project-urbanhus-js": () => import("./../../../src/pages/project/urbanhus.js" /* webpackChunkName: "component---src-pages-project-urbanhus-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

