import React, { Component } from 'react';
import classNames from 'classnames';
import axios from 'axios';

import Wave from "../images/wave"
import icons from '../images/icons.svg';
const NextIcon = icons + '#next';

class MusicPlayer extends Component {

  state = {
    open: false,
    tracks: [],
    tracksFetched: false,
    tracksError: false,
    featuredTrack: null,
  };

  componentDidMount() {

    if(!this.tracksFetched) {
      setTimeout( () => {
        axios.get('/api/spotify')
        .then((response) => {
          if (response.data) {
            const SPOTIFY_TOKEN = response.data;
            axios.get('/api/tracks', {
              params: {
                token: SPOTIFY_TOKEN
              }
            })
            .then((response) => {
              if (response.data) {
                const TRACKS = response.data
                this.setState({ tracks: TRACKS, tracksFetched: true, tracksError: false });
                this.pickRandomTrackFromPlaylist();
              } else {
                // something went wrong
                this.setState({ tracksFetched: true, tracksError: true });
              }
            })
            .catch((error) => {
              // something went wrong
              this.setState({ tracksFetched: true, tracksError: true });
            });
          } else {
            // something went wrong
            this.setState({ tracksFetched: true, tracksError: true });
          }
        })
        .catch((error) => {
          // something went wrong
          this.setState({ tracksFetched: true, tracksError: true });
        });
      }, 500);
    }

  }

  togglePlayer() {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }

  pickRandomTrackFromPlaylist() {
    const TRACKS = this.state.tracks;
    if(!this.state.tracksError && TRACKS) {
      const randomTrack = TRACKS[Math.floor(Math.random() * TRACKS.length)];
      this.setState({ featuredTrack: randomTrack });
    }
  }

  getNextTrack() {
    if(this.state.tracks && this.state.featuredTrack) {
      const index = this.state.tracks.map(e => e.track.id).indexOf(this.state.featuredTrack.track.id);
      const nextIndex = index + 1;
      if (this.state.tracks[nextIndex]) {
        this.setState({ featuredTrack: this.state.tracks[nextIndex] });
      } else {
        this.setState({ featuredTrack: this.state.tracks[0] });
      }
    }
  }

  render() {
    const { open, featuredTrack } = this.state;
    const playerClass = classNames('MusicPlayer', {
      'MusicPlayer--open': open,
    });
    let trackName = null;
    let artistName = null;
    let trackURL = '#';
    if(featuredTrack && featuredTrack.track && featuredTrack.track.name && featuredTrack.track.artists[0] && featuredTrack.track.external_urls && featuredTrack.track.external_urls.spotify) {
      trackName = featuredTrack.track.name;
      artistName = featuredTrack.track.artists[0].name;
      trackURL = featuredTrack.track.external_urls.spotify;
    }
    return (
      <button className={playerClass} aria-label="Open music player">
        <div className="MusicPlayer__button" onClick={ () => this.togglePlayer() }>
          <Wave />
          <Wave />
        </div>
        <div className="MusicPlayer__inner bg-white">
          <a href={trackURL} target="_blank" rel="noopener noreferrer">
            <div className="MusicPlayer__disc">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </a>
          <div className="MusicPlayer__arm">
            <div></div>
          </div>
          <div className="MusicPlayer__next bg-lightgray" onClick={ () => this.getNextTrack() }>
            <svg>
              <use xlinkHref={NextIcon}></use>
            </svg>
          </div>
          <a href={trackURL} target="_blank" rel="noopener noreferrer">
            <div className="MusicPlayer__display">
              { trackName && artistName ? (
                  <>
                    <p> {`${trackName}, ${artistName} - ${trackName}, ${artistName} - ${trackName}, ${artistName} - ${trackName}, ${artistName}`} -</p>
                    <p>{`${trackName}, ${artistName} - ${trackName}, ${artistName} - ${trackName}, ${artistName} - ${trackName}, ${artistName}`} -</p>
                  </>
                ) : (
                  <>
                    <p> Couldn't contact the Spotify API <span role="img" aria-label="skull ">💀</span>, Couldn't contact the Spotify API <span role="img" aria-label="skull ">💀</span> -</p>
                    <p>Couldn't contact the Spotify API <span role="img" aria-label="skull ">💀</span>, Couldn't contact the Spotify API <span role="img" aria-label="skull ">💀</span> -</p>
                  </>
                )
              }
            </div>
          </a>
        </div>
      </button>
    )
  }

}

export default MusicPlayer