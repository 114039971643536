import React, { Component } from 'react';
import classNames from 'classnames';
import throttle from 'lodash.throttle';
import noScroll from 'no-scroll';

import icons from '../images/icons.svg';
const ChevronUp = icons + '#chevron-up';

import FadeInLink from "./transitions/FadeInLink"
import Logo from "./Logo"


class Nav extends Component {

  constructor(props) {
    super(props);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.toggleNoScroll = this.toggleNoScroll.bind(this);
  }

  state = {
    visible: false,
    navOpen: false,
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', throttle( (() => {
        this.onScroll()
      }) , 1000 / 60));
    }
  }

  handleLinkClick(e) {
    // If clicking current page just hide menu again
    if(e.target.hasAttribute('aria-current')) {
      if (typeof window !== 'undefined') {
        document.getElementById('Nav__checkbox').checked = false;
      }
    }
    this.toggleNoScroll();
  }

  onScroll() {
    if (typeof window !== 'undefined') {
      const vp = window.pageYOffset || document.documentElement.scrollTop;
      if(!this.state.visible && vp > 130) {
        this.setState({
          visible: true
        });
      }
      if(this.state.visible && vp < 130 && !this.state.navOpen) {
        this.setState({
          visible: false
        });
      }
    }
  }

  toggleNoScroll() {
    this.setState(prevState => ({
      navOpen: !prevState.navOpen
    }));
    noScroll.toggle();
  }

  render() {
    const { visible } = this.state;
    const triggerClasses = classNames('Nav__trigger', {
      'Nav__trigger--visible': visible,
    });

    return (
      <>
        <input type="checkbox" id="Nav__checkbox" className="Nav__checkbox" />
        <label id="NavTrigger" className={triggerClasses} htmlFor="Nav__checkbox" onClick={this.toggleNoScroll}></label>
        <nav className="Nav bg-white">
          <div className="Nav__inner outer-padding-x">
            <Logo type="nav"/>
            <ul className="Nav__main list-unstyled">
              <li>
                <FadeInLink to="/projects" type="menu" onClick={this.handleLinkClick}><span>01.</span> Projects</FadeInLink>
              </li>
              <li>
                <FadeInLink to="/services" type="menu" onClick={this.handleLinkClick}><span>02.</span> Services</FadeInLink>
              </li>
              <li>
                <FadeInLink to="/about" type="menu" onClick={this.handleLinkClick}><span>03.</span> About</FadeInLink>
              </li>
              <li className="Nav__comingsoon">
                <FadeInLink to="/blog" type="menu"><span>04.</span> Blog <svg className="fill-black"><use xlinkHref={ChevronUp}></use></svg></FadeInLink>
              </li>
              <li>
                <FadeInLink to="/lab" type="menu" onClick={this.handleLinkClick}><span>05.</span> Lab</FadeInLink>
              </li>
              <li>
                <FadeInLink to="/contact" type="menu" onClick={this.handleLinkClick}><span>06.</span> Contact</FadeInLink>
              </li>
            </ul>
            <div className="Nav__secondary">
              <h5>Elsewhere:</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="https://www.linkedin.com/in/henrik-herland-boelsmand-b35b1060/" className="Link--nav" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                </li>
                <li>
                  <a href="https://www.instagram.com/henrik_hhb/" className="Link--nav" target="_blank" rel="noopener noreferrer">Instagram</a>
                </li>
                <li>
                  <a href="https://github.com/wapbamboogie" className="Link--nav" target="_blank" rel="noopener noreferrer">GitHub</a>
                </li>
                <li>
                  <a href="http://codepen.io/henrikboelsmand/" className="Link--nav" target="_blank" rel="noopener noreferrer">Codepen</a>
                </li>
                <li>
                  <FadeInLink to="/newsletter" type="menu" onClick={this.handleLinkClick}>Newsletter</FadeInLink>
                </li>
              </ul>
              <h5>Location:</h5>
              <a href="https://www.google.com/maps/place/Vestergade+18C,+1456+K%C3%B8benhavn/@55.6837315,12.5355653,14z/" target="_blank" rel="noopener noreferrer">
                <address>
                  <span>Vestergade 18C</span>
                  <span>1456 Copenhagen K</span>
                  <span>Denmark</span>
                </address>
              </a>
            </div>
          </div>
        </nav>
        <label className="Nav__overlay" htmlFor="Nav__checkbox" onClick={this.handleLinkClick}></label>
      </>
    )
  }

}

export default Nav
