import React, { Component } from 'react';

class Observer extends Component {

  observer = null;
  elements = null;
  options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.2
  }

  componentDidMount() {
    if (typeof IntersectionObserver !== "undefined") {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if ( entry.isIntersecting ) {
            entry.target.classList.add('show');
            if(entry.target.classList.contains('video')) {
              entry.target.play();
            }
            this.observer.unobserve( entry.target );
          }
        });
      }, this.options);
      if (typeof window !== 'undefined') {
        this.getElementsToWatch();
        this.watchElements();
      }
    } else {
      document.getElementsByTagName('body')[0].classList.add('no-intersection-observer-api');
    }
  }

  componentDidUpdate() {
    if(this.observer) {
      this.observer.disconnect();
    }
    // Allow time for page transitions
    setTimeout( () => {
      this.getElementsToWatch();
      this.watchElements();
    }, 950);
  }

  getElementsToWatch() {
    this.elements = Array.from( document.querySelectorAll('.fadein') );
  }

  watchElements() {
    this.elements.forEach(element => {
      this.observer.observe(element);
    });
  }

  render() {
    return (
      <></>
    )
  }

}

export default Observer