import React from "react"

const Wave = () => (
  <svg className="fill-black" version="1.1" viewBox="0 0 46.1 27" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" >
    <title>Sound waves</title>
    <path d="M46.1,3c-1.2,0.6-2.5,7.2-3.2,10.8C41.5,21.2,40.4,27,36.9,27c-3.5,0-4.7-5.8-6.1-13.2c-0.7-3.5-1.9-10-3.1-10.8
    c-1.2,0.8-2.5,7.2-3.1,10.8C23.1,21.2,22,27,18.5,27c-3.5,0-4.7-5.8-6.1-13.2c-0.7-3.5-1.9-10-3.1-10.8c-1.2,0.8-2.5,7.3-3.1,10.8
    C4.7,21.2,3.6,27,0.1,27L0,24c1.2-0.6,2.5-7.2,3.2-10.8C4.6,5.8,5.7,0,9.3,0c3.5,0,4.7,5.8,6.1,13.2c0.7,3.5,1.9,10,3.1,10.8
    c1.2-0.8,2.5-7.3,3.1-10.8C23,5.8,24.1,0,27.7,0c3.5,0,4.7,5.8,6.1,13.2c0.7,3.5,1.9,10,3.1,10.8c1.2-0.8,2.5-7.2,3.1-10.8
    C41.4,5.8,42.5,0,46.1,0L46.1,3z"/>
  </svg>
)

export default Wave