import React from "react"
import PropTypes from "prop-types"

import '../fonts/index.scss'
import '../styles/app.scss'

import Header from "../components/Header"
import Footer from "../components/Footer"

const Layout = ({ children }) => {
  return (
    <div className="wrapper">
      <Header/>
        <div className="Layout">{children}</div>
      <Footer/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
